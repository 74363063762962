import { useCallback, useEffect, useRef, useState } from 'react';
import { adjustTip, startRosswarepayTransaction } from './helpers/api';
import { getExpectedUrlParams } from './helpers/urlParams';
import { isHostMobile, sendMessageToHost } from './helpers/host';
import { useFullsteamScript } from './helpers/useFullsteam';
import ManualPaymentScreen from './components/ManualPaymentScreen';
import TokenSelectionScreen from './components/TokenSelectionScreen';
import UseTokenScreen from './components/UseTokenScreen';
import RefundScreen from './components/RefundScreen';
import ObscuringLoader from './components/ObscuringLoader';
import PaymentCompleteScreen from './components/PaymentCompleteScreen';
import TipScreen from './components/TipScreen';
import { isString } from 'lodash';

const params = getExpectedUrlParams();

export default function App() {
  // general loading screen (overlays everything else)
  const pageLoadTime = useRef(new Date().getTime());

  // general error (overlays everything else)
  const [error, setError] = useState(!(params?.business_id && params?.business_password) ? 'Missing business_id or business_password' : null);
  const [tokensEnabled, setTokensEnabled] = useState(false);
  const [rosswarePayConfiguration, setRosswarePayConfiguration] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [authenticationKey, setAuthenticationKey] = useState(null);
  const [modifiedAmounts, setModifiedAmounts] = useState(null);
  const [screen, setScreen] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [screenParams, setScreenParams] = useState(null);
  const [finalizeParams, setFinalizeParams] = useState(null);

  const [loading, setLoading] = useState(true);

  const [enableMobileTokens] = useState(true); // this should come from the api eventually. set it manually for now.

  const setGlobalError = useCallback((err) => {
    clearInterval(window.pingerInterval);
    setError(err);
  }, [setError]);

  const sendMessageToHostCallback = useCallback((message) => {
    Object.assign({ error }, message);
    sendMessageToHost(message);
  }, [error]);

  const onCancel = () => {
    setGlobalError('Payment cancelled');
  }

  const finalizePayment = (data) => {
    setScreen('payment-complete');
    sendMessageToHost(data);
  }

  const onPaymentSuccess = (data) => {
    if (rosswarePayConfiguration?.enable_tips && params.requester_id) { // require that the sending app has provided a requester_id for us to assign the tips to..
      setScreen('tip');
      setScreenParams({ ...data, configuration: rosswarePayConfiguration });
      setFinalizeParams(data);
    } else {
      finalizePayment(data);
    }
  }

  const finalizeTip = async (tipAmount, basis) => {
    if (tipAmount && parseFloat(tipAmount) > 0) {
      const response = await adjustTip({ transaction_id: finalizeParams.transaction_id, invoice_number: params.invoice_number, schedule_item_id: params.schedule_item_id, amount: tipAmount, recipient: params.requester_id, basis, accounted_in_work: false, source_app: 'terminal.rosswarepay' });
      if (!response.ok) {
        const responseText = await response.text();
        console.log('failed to save tip', responseText);
        setGlobalError(responseText);
        return;
      }
    }
    finalizePayment(finalizeParams);
  }

  // set timeout stuff
  useEffect(() => {
    if (params.timeout) {
      let cycle = setInterval(() => {
        let remaining = (params.timeout * 1000 * 60) - (new Date().getTime() - pageLoadTime.current);
        console.log('remaining', remaining);
        if (remaining < 0) { // timeout is in minutes
          setGlobalError('The payment request has timed out. Please exit this window and retry the payment from ServiceDesk.');
          clearInterval(cycle);
        }
      }, 950);

      return () => clearInterval(cycle);
    }
  }, [setGlobalError]);

  const controlScriptLoaded = useFullsteamScript(params.unique_id);

  useEffect(() => {
    if (controlScriptLoaded && params && !error) {
      const runner = async () => {
        try {
          const { code, data } = await startRosswarepayTransaction({
            ...params,
            context: params?.refund ? 'token' : 'payment', // refunds need a different kind of auth key
          });
          setCountryCode(data?.countryCode);
          console.log('started hosted transaction');

          if (code !== 200) {
            console.log('bad code', code);
            setGlobalError(data?.message);
            sendMessageToHostCallback({ unique_id: params.unique_id, context: 'create_row', success: false, done: true });
          } else {
            if (!data?.isSuccessful) {
              throw new Error('Failed to get auth key, please check Merchant credentials and try again.');
            }
            if (params.refund === true) {
              setScreen('refund');
            } else {
              setTokens(data?.tokens);
              setTokensEnabled((!!data.enableTokens)); // as of 7/19/23 enableMobileTokens doesn't actually exist. We need to create the UI to change this setting and provide it but aren't doing it at the moment. Instead we are just going to turn the mobile tokens off entirely, temporararily in favor of Glade's SD tokens.
              setRosswarePayConfiguration(data?.rosswarePayConfiguration);

              if (data.tokens?.length && isHostMobile() && enableMobileTokens && tokensEnabled) {
                setScreen('choose-token');
                // received tokens to use and we're allowed to use them
                // todo: when this is true show the token selection screen
              }
            }
            setModifiedAmounts(data?.amountDetails);
            setAuthenticationKey(data?.authenticationKey); // will trigger setup hook
            setLoading(false);
          }
        } catch (err) {
          console.log('error in initialization hook', err);
          setGlobalError(err.message);
          sendMessageToHostCallback({ unique_id: params.unique_id, context: 'create_row_code', success: false, done: true });
        }
      };
      runner();
    }
  }, [controlScriptLoaded, setGlobalError, sendMessageToHostCallback, error, setLoading, enableMobileTokens, tokensEnabled]);

  if (error) {
    clearInterval(window.pingerInterval);
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="border rounded wrapper p-4 bg-white">
          {isString(error) ? error : JSON.stringify(error)}
        </div>
      </div>
    );
  }

  if (!controlScriptLoaded || loading) {
    return <ObscuringLoader />
  }

  let view;

  if (screen === 'choose-token' && enableMobileTokens && tokensEnabled && tokens?.length) { // we only want to allow choosing of tokens if enableMobileTokens is true
    view = (
      <TokenSelectionScreen countryCode={countryCode} setGlobalError={setGlobalError} onCancel={onCancel} tokens={tokens} onChooseToken={token => {
        setScreen('token-payment');
        setScreenParams({ token });
        console.log('chose token ', token);
      }} switchScreen={(chosen, params) => {
        setScreen(chosen);
        setScreenParams(params);
      }} />
    );
  } else if (screen === 'payment-complete') {
    view = (
      <PaymentCompleteScreen countryCode={countryCode} payer={screenParams?.payer} />
    );
  } else if (screen === 'tip') {
    view = (
      <TipScreen countryCode={countryCode} configuration={rosswarePayConfiguration} onFinish={finalizeTip} amount={params.amount} modifiedAmounts={modifiedAmounts} />
    );
  } else if (screen === 'token-payment') {
    view = (
      screenParams?.token && <UseTokenScreen countryCode={countryCode} onPaymentSuccess={onPaymentSuccess} setGlobalError={setGlobalError} onCancel={onCancel} token={screenParams?.token} modifiedAmounts={modifiedAmounts} switchScreen={(chosen, params) => {
        setScreen(chosen);
        setScreenParams(params);
      }}/>
    );
  } else if (screen === 'refund') {
    view = (
      <RefundScreen countryCode={countryCode} setGlobalError={setGlobalError} onCancel={onCancel} tokens={tokens} tokensEnabled={tokensEnabled} enableMobileTokens={enableMobileTokens} authenticationKey={authenticationKey} controlScriptLoaded={controlScriptLoaded} switchScreen={(chosen, params) => {
        setScreen(chosen);
        setScreenParams(params);
      }} />
    );
  } else {
    view = (
      <ManualPaymentScreen countryCode={countryCode} onPaymentSuccess={onPaymentSuccess} setGlobalError={setGlobalError} onCancel={onCancel} tokens={tokens} tokensEnabled={tokensEnabled} enableMobileTokens={enableMobileTokens} authenticationKey={authenticationKey} modifiedAmounts={modifiedAmounts} controlScriptLoaded={controlScriptLoaded} switchScreen={(chosen, params) => {
        setScreen(chosen);
        setScreenParams(params);
      }} />
    );
  }

  return (
    <div className="main-container">
      {view}
    </div>
  )
}
