export function getParamsFromUrl(expectedParams) {
  const queryParams = new URLSearchParams(window.location.search);
  const paramsObject = {};
  expectedParams.forEach(param => {
    paramsObject[param] = queryParams.get(param);
  });
  return paramsObject;
}

/**
 * @returns {{ unique_id: string, business_id: string, business_password: string, requester_id: string, amount: string, tax: string, line_items: string, zip_code: string, payer_name: string, invoice_number: string, timeout?: int, refund: boolean, calling_platform: string }} params
 */
export function getExpectedUrlParams() {
  const raw = getParamsFromUrl(['unique_id', 'business_id', 'business_password', 'requester_id', 'amount', 'tax', 'line_items', 'zip_code', 'payer_name', 'invoice_number', 'timeout', 'refund', 'schedule_item_id', 'platform', 'force_duplicate', 'country']);
  raw.refund = (raw.refund === 'true' || raw.refund === '1' || raw.refund === 1);
  raw.timeout = raw.timeout ? parseInt(raw.timeout) : raw.timeout;
  return raw;
}

export function getUrlParam(urlParam) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(urlParam);
}
